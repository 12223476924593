import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { hashString } from '../utils/helpers';

type ExperienceProps = {
  experiences: { node: any }[];
};
const ExperienceList = ({ experiences }: ExperienceProps) => {
  return (
    <>
      {experiences.map((exp: any) => {
        const { frontmatter } = exp.node;
        const id = hashString(frontmatter.title + frontmatter.role);

        return (
          <div key={id} className="experience--block">
            <div className="experience--row">
              <h4>{frontmatter.role}</h4>
              <h4>{`${frontmatter.startDate}${
                frontmatter.endDate ? ` - ${frontmatter.endDate}` : ''
              }`}</h4>
            </div>
            <div className="experience--row">
              <h4>{frontmatter.experienceCompany}</h4>
            </div>
            <div className="experience--row">
              <h5>{frontmatter.experienceLocation}</h5>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query ExperienceQuery {
        allMdx(
          filter: {
            frontmatter: { templateKey: { eq: "experience-page" } }
          }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                role
                startDate
                title
                experienceLocation
                experienceCompany
                endDate
              }
            }
          }
        }
      }
    `}
    render={data => (
      <ExperienceList experiences={data.allMdx.edges} />
    )}
  />
);
