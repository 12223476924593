import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';

type AboutProps = {
  content: {
    description: string;
    subtitle: string;
    title: string;
  };
};
const About = ({ content }: AboutProps) => (
  <section id="about" style={{ background: `#fafafa` }}>
    <div>
      <div>
        <h1>{content.title}</h1>
        <h2>{content.subtitle}</h2>
      </div>
      <div>
        <p>{content.description}</p>
      </div>
    </div>
  </section>
);

export default () => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        allMdx(filter: { slug: { eq: "sections/about" } }) {
          edges {
            node {
              frontmatter {
                title
                subtitle
                description
              }
            }
          }
        }
      }
    `}
    render={data => (
      <About content={data.allMdx.edges[0].node.frontmatter} />
    )}
  />
);
