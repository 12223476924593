// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';
import ExperienceList from './experienceList';
import EducationList from './educationList';
import '../assets/styles/experience.scss';

const Experience = () => {
  return (
    <section id="experience">
      <div className="flex-container">
        <div className="work">
          <h2>Experience</h2>
          <ExperienceList />
        </div>

        <div className="education">
          <h2>Education</h2>

          <EducationList />
        </div>
      </div>
    </section>
  );
};

export default Experience;
