import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
const LinkedIn = require('../assets/icons/linkedin.svg');
const Mail = require('../assets/icons/mail.svg');
const Phone = require('../assets/icons/phone.svg');
import '../assets/styles/contact.scss';

type ContactProps = {
  details: {
    email: string;
    linkedin: string;
    phone: string;
    title: string;
  };
};

const Contact = ({ details }: ContactProps) => (
  <section
    id="contact"
    className="contect"
    style={{ background: `#2B3236` }}
  >
    <div>
      <h2 style={{ color: '#fff' }}>{details.title}</h2>
      <div className="contact--options">
        <a href={details.linkedin}>
          <LinkedIn />
        </a>
        <a href={`mailto:${details.email}`}>
          <Mail />
        </a>
        <a href={`tel:${details.phone}`}>
          <Phone />
        </a>
      </div>
    </div>
  </section>
);

export default () => (
  <StaticQuery
    query={graphql`
      query ContactQuery {
        allMdx(filter: { slug: { eq: "sections/contact" } }) {
          edges {
            node {
              frontmatter {
                title
                linkedin
                email
                phone
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Contact details={data.allMdx.edges[0].node.frontmatter} />
    )}
  />
);
