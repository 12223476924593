/* eslint no-bitwise: ["error", { "allow": ["<<", "&"] }] */

export const hashString = (s: string) =>
  s.split('').reduce((a, b) => {
    const c = (a << 5) - a + b.charCodeAt(0);
    return c & c;
  }, 0);

export const isObjEmpty = (obj: {}) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

