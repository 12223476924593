// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../components/about';
import Contact from '../components/contact';
import Experience from '../components/experience';
import Projects from '../components/projects';

const IndexPage = () => (
  <Layout>
    <SEO title="David Hellman" />

    <About />
    <Experience />
    <Projects />
    <Contact />
  </Layout>
);

export default IndexPage;
