// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ProjectBlock from './projectBlock';
import { hashString } from '../utils/helpers';
import '../assets/styles/projects.scss';

type ProjectProps = {
  projects: {
    node: {
      frontmatter: {
        path: string;
        projectDescription: string;
        title: string;
        thumbnail: string;
      };
    };
  }[];
};

const Projects = ({ projects }: ProjectProps) => {
  const leftProjects = [] as any;
  const rightProjects = [] as any;

  projects.forEach((project, index) => {
    if (index % 2 === 0) {
      leftProjects.push(project.node.frontmatter);
    } else {
      rightProjects.push(project.node.frontmatter);
    }
  });

  return (
    <section id="projects" style={{ background: `#fafafa` }}>
      <div>
        <div>
          <h1>Projects</h1>
        </div>
        <div className="projects--block">
          <div className="projects--block--left">
            {leftProjects.map((project: any, index: any) => (
              <ProjectBlock
                key={hashString(project.title)}
                blockType={index % 2 === 0 ? 'square' : 'rect'}
                description={project.projectDescription}
                redirectPath={project.path}
                title={project.title}
                thumbnail={project.thumbnail}
              />
            ))}
          </div>
          <div className="projects--block--right">
            {rightProjects.map((project: any, index: any) => (
              <ProjectBlock
                key={hashString(project.title)}
                blockType={index % 2 === 0 ? 'rect' : 'square'}
                description={project.projectDescription}
                redirectPath={project.path}
                title={project.title}
                thumbnail={project.thumbnail}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectDescription {
        allMdx(
          filter: {
            frontmatter: { templateKey: { eq: "project-page" } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          edges {
            node {
              frontmatter {
                path
                title
                projectDescription
                thumbnail
              }
            }
          }
        }
      }
    `}
    render={data => <Projects projects={data.allMdx.edges} />}
  />
);
