import * as React from 'react';
import { graphql, useStaticQuery, StaticQuery } from 'gatsby';
import { hashString } from '../utils/helpers';

type EducationProps = {
  educations: { node: any }[];
};
const EducationList = ({ educations }: EducationProps) => {
  return (
    <>
      {educations.map((edu: any) => {
        const { frontmatter } = edu.node;
        const id = hashString(frontmatter.title + frontmatter.role);

        return (
          <div key={id} className="experience--block">
            <div className="experience--row">
              <h4>{frontmatter.degree}</h4>
              <h4>{`${frontmatter.startDate}${
                frontmatter.endDate ? ` - ${frontmatter.endDate}` : ''
              }`}</h4>
            </div>
            <div className="experience--row">
              <h4>{frontmatter.educationTitle}</h4>
            </div>
            <div className="experience--row">
              <h5>{frontmatter.educationLocation}</h5>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query EduQuery {
        allMdx(
          filter: {
            frontmatter: { templateKey: { eq: "education-page" } }
          }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                degree
                startDate
                educationLocation
                educationTitle
                title
                endDate
              }
            }
          }
        }
      }
    `}
    render={data => <EducationList educations={data.allMdx.edges} />}
  />
);
