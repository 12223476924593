// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';
import { Link } from 'gatsby';
import '../assets/styles/projectBlock.scss';

type ProjectProps = {
  blockType: 'rect' | 'square';
  description: string;
  redirectPath: string;
  title: string;
  thumbnail: string;
};

const ProjectBlock: React.FC<ProjectProps> = ({
  blockType,
  description,
  redirectPath,
  title,
  thumbnail,
}) => {
  return (
    <div className={`project--block-${blockType}`}>
      <div className="overlay" />
      <img
        className="project--thumbnail"
        src={thumbnail}
        alt={`${title} thumbnail`}
      />
      <div className="project--description">
        <div>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        <Link to={`/project${redirectPath}`}>
          <button className="button--secondary">VIEW WORK</button>
        </Link>
      </div>
    </div>
  );
};

export default ProjectBlock;
